<template>
  <v-container class="bg-glass-effect px-3 pt-9" fluid>
    <Back/>
    <v-col class="title"  cols="12">
      <span >Novo Atendimento</span>
    </v-col>
    <v-col cols="12" v-for="(option, index) in options" :key="index" >
      <v-card
          @click="newCase(option)"
          class="card"
          elevation="9"
          max-height="80px"
      >
        <v-card-text class="pt-3">
          <v-row>
            <v-col class="mt-2" cols="2">
              <v-img  max-height="30" max-width="30" :src="require(`../../../public/img/icons/atendimento/${option.icon}`)"></v-img>
            </v-col>
            <v-col class="title-option pt-6 mb-3 font-weight-bold" cols="8" >{{ option.title }}</v-col>
            <v-col cols="2">
              <v-btn icon>
              <v-img class="mb-3 mt-4" max-height="20" max-width="9" :src="require('../../../public/img/icons/commons/chevron-right-icon.svg')"></v-img>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import Back from '@/components/covid/back-arrow/index'


export default {
  name: "miniMenuCovid",
  components: {
    Back,
  },
  data: () => ({
    options: [],
  }),
  created () {
    this.loadOptions();
  },
  methods:{
    loadOptions(){
      this.options = this.$auth.getMenuNick()
        .filter(menu => menu.title == 'Atendimento')
        .map(menu => menu.subMenus)
        .reduce(Array)
        .map(menu => ({
          title: menu.title,
          options: menu.options,
          icon: menu.icon,
          link: menu.link,
          type: menu.type,
        }));
    },
    newCase(menu){
      this.$util.link(menu.link, {optionTitle : menu.title, optionType: menu.options}, this.getJsonQueryParams(menu.options));
    },
    getJsonQueryParams(params){
      try {
        return JSON.parse(params)
      } catch (e) {
        return null;
      }
    }
  }
}
</script>

<style scoped>

/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.7) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
}

.title {

  align-self: center;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */


  color: #4A499B;
}

.title-option{
  align-self: center;
  vertical-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 22px */


  color: #4A499B;
}

.card{
  background: #F8F8FF;
  border-radius: 20px;
}

</style>


